import { reportClientError } from '/machinery/reportClientError'

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

export function pushToDataLayerWithCallback(data) {
  return waitForWithTimeout(
    3000,
    new Promise((resolve) => {
      try {
        if (!data) throw new Error(`No data given to pushToDataLayer function`)
        window.dataLayer.push({
          ...removeUndefinedValues(data),
          eventCallback: resolve,
        })
      } catch (e) {
        reportClientError(e)
        resolve()
      }
    })
  ).catch(reportClientError)
}

async function waitForWithTimeout(milliseconds, promise) {
  // check for which promise resolves faster (Promise.race)
  const result = await Promise.race([
    promise,
    timeoutPromise(milliseconds),
  ])

  if (result === timeout) return

  return result
}

const timeout = Symbol('timeout')

function timeoutPromise(milliseconds) {
  return new Promise(resolve =>
    setTimeout(() => resolve(timeout), milliseconds)
  )
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
